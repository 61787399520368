import { ITheme } from './iTheme';

export const LandmarkTheme: ITheme = {
    name: 'LandmarkTheme',
    properties: {
        '--loginbackground': 'url(../../../assets/images/background.png)',
        '--logindialogcolor': 'rgba(236, 236, 236, 0.8)',
        '--loginfontcolor': '#000',
        '--buttonfontcolor': '#fff',
        '--secondary-btn-font-clr':'#2e2e38',
        '--primary': '#3aaf4a',
        '--secondary': '#f8f9fa',
        '--tertiary': '#1A1A24',
        '--nav': '#fff',
        '--navtext': '#193240',
        '--navtext-secondary': '#fff',
        '--navbar': '#3aaf4a',
        '--navmenuitemselected': '#3aaf4a',
        '--navmenuitemselectedborder': '#ffc72a',
        '--footer': '#d7ede5',
        '--success': '#06ba06',
        '--warning': '#ff9831',
        '--error': '#d80d0d',
        '--info': '#3c5a99',
        '--font': 'Calibri',
        '--loginlogo': 'Landmark-logo.png',
        '--logo': 'Landmark-logo.png',
        '--footerlogo': 'Landmark-stacked-logo.png',
        '--loginlogowidth': '115px',
        '--favicon': 'landmark-favicon-32.png',
        '--logo-title-align': '1.5rem',
        '--reportlogo': 'Landmark-stacked-logo.png', // Only supports JPEG and PNG formats.
        '--reportfontsize': '11',
        '--reportlogoposistion': 'right',
        '--reportlogoHeight': 45,
        '--font-2': 'Roboto-Bold',
        '--navlogoheight': '25px',
        '--mandatoryJur-cev2' :'#00000',
        '--eligibleJur-cev2':'#00000',
        '--pyElectedInJur-cev2':'#00000',
        '--pyElectedOutJur-cev2':'#00000',
        '--selectionBoxTextColor-cev2':'#000',
        '--reportHeadingFontSize': '14',
        //BELOW COLOR CODES TO BE USED IN CASE THE JURISDICTIONS ARE REQUIRED TO BE COLOR CODED IN SELECTION BOX
        // '--mandatoryJur-cev2' :'#168736',
        // '--eligibleJur-cev2':'#eb4f00',
        // '--pyElectedInJur-cev2':'#747480',
        // '--pyElectedOutJur-cev2':'#155CB4',
        // '--selectionBoxTextColor-cev2':'#fff'
        '--mandatoryInfoboxColor-cev2':'#168736',  
        '--eligibleInfoboxColor-cev2':'#eb4f00',  
        '--pyElectedInInfoboxColor-cev2':'#747480',  
        '--pyElectedOutInfoboxColor-cev2':'#155CB4', 
        '--infoboxMandatoryFontColor-cev2':'#fff',    
        '--isOTPFeatureEnabled': false,
        '--isOTPSecondAuthFactorBypassed': false,         
        '--isMYEYLoginEnabled': true,
        '--isZipCodeSecondFactorEnabled': true,
        '--isTINSecondFactorEnabled': true,
        '--isPartnerCodeSecondFactorEnabled': false,
        '--headerColorv3': '#FFFFFF', //--nav
        '--footerColorv3': '#d7ede5', //--footer
        '--headerFontColorv3': '#193240', //--nav-text
        '--footerFontColorv3': '#193240',//--nav-text
        '--primaryColorv3':'#3aaf4a',
        '--electionCountv3':'#3aaf4a',
        '--linkColorv3':'#3aaf4a',
        '--headerLogov3': 'Landmark-logo.png',
        '--headerLogoHtv3': '30px',
        '--showFooterLogo': false,
        '--isEYBranding': false
    }
};

