import { IEntity } from 'src/app/shared/models/iEntity';

export const colorOffWhite = '#f6f6fa';
export const colorRed3 = '#f95d54';
export const colorOrange3 = '#eb4f00';
export const colorGreen3 = '#168736';
export const colorTeal3 = '#0d7575';
export const colorGray2 = '#c4c4cd';
export const colorPurple='#750e5c';

export const applicationMenuRoutes = {
  partnerinfo: { routeName: 'partnerinfo', routeUrl: '/home/partnerinfo', icon: '', name: 'Partner Info' },
  composite: { routeName: 'composite', routeUrl: '/home/composite', icon: '', name: 'Composite' },
  withholding: { routeName: 'withholding', routeUrl: '/home/withholding', icon: '', name: 'Withholding' },
  documents: { routeName: 'documents', routeUrl: '/home/documents', icon: '', name: 'Documents' }
};

export const interceptorMessages = {
  notAuthenticated: 'Cannot send request to registered endpoint if the user is not authenticated.'
};

export const withholdingStageRoutes =
  [
    {
      name: 'Select Entity',
      selected: true,
      url: 'edit/entity'
    },
    {
      name: 'Instructions',
      selected: false,
      url: 'edit/instructions'
    },
    //{
    //  name: 'Partner Information',
    //  selected: false,
    //  url: 'edit/partner-information'
    //},
    {
      name: 'Select Withholding Exemptions',
      selected: false,
      url: 'edit/exemptions'
    },
    {
      name: 'Sign and Submit',
      selected: false,
      url: 'edit/submit'
    }
  ];

export const compositeStageRoutes =
  [
    {
      name: 'Instructions',
      selected: true,
      url: 'instructions'
    },
    {
      name: 'Select Composite Elections',
      selected: false,
      url: 'elections'
    },
    {
      name: 'Sign and Submit',
      selected: false,
      url: 'submit'
    }
  ];


  export const compositeStageRoutesV2 =
  [
    {
      name: 'Instructions',
      selected: true,
      url: 'instructions'
    },
    {
      name: 'Select State Source Income from Other Activities',
      selected: false,
      url: 'statesourceincome'
    },
    {
      name: 'Composite Elections',
      selected: false,
      url: 'electionoption'
    },
    {
      name: 'Select Composite Elections',
      selected: false,
      url: 'elections'
    },
    {
      name: 'Sign and Submit',
      selected: false,
      url: 'submit'
    }
  ];


export const dashboardMapLegend = [
  {
    color: colorRed3,
    text: 'Mandatory Jurisdictions',
    value: 0
  },
  {
    color: colorGreen3,
    text: 'Elected Jurisdictions (Documents Not Required)',
    value: 2
  },
  {
    color: colorTeal3,
    text: 'Not Elected Jurisdictions',
    value: 3
  },
  {
    color: colorGray2,
    text: 'Not Available Jurisdictions',
    value: 4
  }
];

export const CompositeDashboardMapLegendForNewUI = [
  {
    color: '#FFE600',
    text: 'Mandatory Jurisdictions',
    value: 0
  },
  {
    color: '#00B050',
    text: 'Elected Jurisdictions (Documents Not Required)',
    value: 2
  },
  {
    color: '#4586BF',
    text: 'Not Elected Jurisdictions',
    value: 3
  },
  {
    color: '#c4c4cd',
    text: 'Not Available Jurisdictions',
    value: 4
  }
];

export const withholdingMapLegend = [
  {
    color: colorPurple,
    text: 'Composite Jurisdictions',
    value: 0
  },
  {
    color: colorOrange3,
    text: 'Exempt Jurisdictions (Documents Required)',
    value: 1
  },
  {
    color: colorGreen3,
    text: 'Exempt Jurisdictions (Documents Not Required)',
    value: 2
  },
  {
    color: colorTeal3,
    text: 'Non-Exempt Jurisdictions',
    value: 3
  },
  {
    color: colorGray2,
    text: 'Not Available',
    value: 4
  }
];


export const NotificationMessage = {
  genericError: 'Some error occured, please contact administrator',
  partnerInfoSubmit: 'Information updated successfully',
  instructionErrorMessage: 'Instructions page language is not configured',
  SubmitLanguageErrorMessage: 'Sign & Submit page language is not configured',
  partnerConsentMessageComposite:'Please consent in withholding service first',
  stateSourceIncomeLanguageErrorMessage: 'State Source Income page language is not configured',
  electionOptionHeaderContentErrorMessage:'Election option header language is not configured',
  exemptionPageListHeaderErrorMessage:'Exemption selected & available list header language is not configured'
};

export const DocumentMessage = {
  downloadErrorMessage: 'The selected file has not been downloaded correctly.',
  notProcessedError: 'The following files are not processed : ',
  processing: (current: number, total: number) => `Processing ${current} out of ${total} file(s)`,
  processed: (success: number, total: number) => `Processed ${success} out of ${total} file(s)`,
  taxDocumentsBindingWarningMessage: 'No tax documents have been uploaded.',
  taxDocumentsBindingErrorMessage: 'Error occured.The server did not return any tax documents.',
  taxDocumentsFileMissingErrorMessage: 'The corresponding tax document file does not exist.',
  consentLanguageError: 'Error occured while fetching consent language',
  consentDataError: 'An error occured while fetching consent data',
  consentCheckError: 'An error occured while fetching consent check info',
  noFundsError: 'No Funds available',
  consentInfoSaved: 'Information saved successfully',
  consentSaveError: 'An error occured while saving data',
  documentSelectWarning: 'Please select the document(s) to proceed!',
  documentDeletedSuccess: 'Document(s) deleted successfully',

  bulkDownloadHeaderMessage: 'Bulk Download',
  bulkDownloadErrorMessage: 'Error occurred while processsing your request for Bulk Download. Please Contact Administrator.',
  bulkDownloadStatusMessage: 'Only the selected files will be processed for downloading.',
  bulkDownloadMessage: 'Your request to download the selected consented files have been accepted. You will receive an email with a link to download the selected consented files if processing is completed successfully.',
  bulkDownloadTDDMessage: 'Your request to download the selected files have been accepted. You will receive an email with a link to download the selected files if processing is completed successfully.', 
  bulkDownloadTaxDocStatusMessage: 'Only partner consented files will be processed for downloading.',
};
export const validationRegex: string = "[^\\s].*";   //'^[a-zA-Z0-9!@#$?–%&:\'\""()*\+,.\/;\[\\\]\^_`\{|}~ \s -]+$';
export const nameRelationshipRegex: string = "[a-zA-Z0-9.',]+(\\s+[a-zA-Z0-9.',&]+)*";
export const nameRegex: string = "[\\sa-zA-Z0-9.',-]+(\\s+[a-zA-Z0-9.',&\\s]+-)*"; 
export const placeholderPartnerGridHomeScreen = '{@PartnersGrid}';

export const preloadTimer: number = 1000;
export const CommonErrorMessage: string = 'An unexpected error occurred! Please refresh the page or contact administrator.';

//Commenting this out due to GS request to not show Prior year and new in the title  - applicable for all clients
// export const piorYearHeaderText=" - Prior year";
// export const newHeaderText=" - New";

export const piorYearHeaderText="";
export const newHeaderText="";

