import { ITheme } from './iTheme';
export const GSTheme: ITheme = {
    name: 'GoldmanSachsTheme',
    properties: {
        '--loginbackground': 'url(../../../assets/images/background.png)',
        '--logindialogcolor': 'rgba(41, 38, 38, 0.7)',
        '--loginfontcolor': '#fff',
        '--buttonfontcolor': '#2e2e2e',
        '--secondary-btn-font-clr': '#2e2e2e',
        '--primary': '#FFE600',
        '--secondary': '#f8f9fa',
        '--tertiary': '#1A1A24',
        '--nav': '#1A1A24',
        '--navtext': '#fff',
        '--navtext-secondary': '#fff',
        '--navbar': '#2E2E38',
        '--navmenuitemselected': '#1A1A24',
        '--navmenuitemselectedborder': '#ffe600',
        '--footer': '#1A1A24',
        '--success': '#06ba06',
        '--warning': '#ff9831',
        '--error': '#d80d0d',
        '--info': '#3c5a99',
        '--font': 'EYInterstate Light',
        '--loginlogo': 'ey-logo-black.png',
        '--logo': 'ey-logo.svg',
        '--footerlogo': 'ey-logo-with-tagline.svg',
        '--loginlogowidth': '50px',
        '--favicon': 'favicon.ico',
        '--logo-title-align': '3rem',
        '--reportlogo': 'ey-report-logo.png', // Only supports JPEG and PNG formats.
        '--reportfontsize': '10',
        '--reportlogoposistion': 'right',
        '--reportlogoHeight': 50,
        '--font-2': 'EYInterstate Regular',
        '--navlogoheight': '25px',
        '--mandatoryJur-cev2' :'#00000',
        '--eligibleJur-cev2':'#00000',
        '--pyElectedInJur-cev2':'#00000',
        '--pyElectedOutJur-cev2':'#00000',
        '--selectionBoxTextColor-cev2':'#000', 
        '--mandatoryInfoboxColor-cev2':'#FFE600',  
        '--eligibleInfoboxColor-cev2':'#00B050',  
        '--pyElectedInInfoboxColor-cev2':'#747480',  
        '--pyElectedOutInfoboxColor-cev2':'#155CB4', 
        '--infoboxMandatoryFontColor-cev2':'#2e2e2e',       
        '--isOTPFeatureEnabled': true,
        '--isOTPSecondAuthFactorBypassed': false,           
        '--isMYEYLoginEnabled': true,
        '--isZipCodeSecondFactorEnabled': true,
        '--isTINSecondFactorEnabled': false,
        '--isPartnerCodeSecondFactorEnabled': false,        
        '--reportHeadingFontSize': '14',
        '--headerColorv3': '#FFFFFF',
        '--footerColorv3': '#FFFFFF',
        '--headerFontColorv3': '#000000',
        '--footerFontColorv3': '#000000',
        '--primaryColorv3':'#000000',
        '--electionCountv3':'#A40011',
        '--linkColorv3':'#0A558E',
        '--headerLogov3': 'ey-logo-black.png',
        '--headerLogoHtv3': '38px',
        '--showFooterLogo': false,
        '--isEYBranding': true
    }
};

