<!-- <app-login-modal>
    <div class="content" *ngIf="otpValidationView || secondFactorValidationView">
        <a href="javascript:void(0)" class="back-btn" (click)="backBtnClicked()">
            Back</a>
        <br />
    </div>
    <div class="content" *ngIf="!isMYEYLoginEnabled && enableOTPFeature && !otpValidationView && !secondFactorValidationView">
        <p class="mb-0 text-center">
            Sign In using Verification Code
        </p>
    </div>
    <div class="content" *ngIf="secondFactorValidationView && isTINenabled && !isZipCodeenabled && !isPartnerCodeEnabled">
        <p class="mb-0 text-center">
            Enter the last 4 digits of Tax Identification Number to Sign In
        </p>
    </div>
    <div class="content" *ngIf="secondFactorValidationView && isZipCodeenabled && !isTINenabled && !isPartnerCodeEnabled">
        <p class="mb-0 text-center">
            Enter the Zip Code to Sign In
        </p>
    </div>
    <div class="content" *ngIf="secondFactorValidationView && !isZipCodeenabled && !isTINenabled && isPartnerCodeEnabled">
        <p class="mb-0 text-center">
            Enter the Partner Code to Sign In
        </p>
    </div>
    <div class="content" *ngIf="secondFactorValidationView && isZipCodeenabled && isTINenabled && !isPartnerCodeEnabled">
        <p class="mb-0 text-center">
            Enter the last 4 digits of Tax Identification Number or Zip Code to Sign In
        </p>
    </div>
    <div class="content" *ngIf="secondFactorValidationView && !isZipCodeenabled && isTINenabled && isPartnerCodeEnabled">
        <p class="mb-0 text-center">
            Enter the Partner Code or last 4 digits of Tax Identification Number to Sign In
        </p>
    </div>
    <div class="content" *ngIf="secondFactorValidationView && isZipCodeenabled && !isTINenabled && isPartnerCodeEnabled">
        <p class="mb-0 text-center">
            Enter the Partner Code or Zip Code to Sign In
        </p>
    </div>
    <div class="content" *ngIf="secondFactorValidationView && isTINenabled && isZipCodeenabled && isPartnerCodeEnabled">
        <p class="mb-0 text-center">
            Enter the Partner Code or last 4 digits of Tax Identification Number or Zip Code to Sign In
        </p>
    </div>
    <div class="content" *ngIf="otpValidationView && !secondFactorValidationView">
        <p class="mb-0 text-center">
            Enter the verification code sent to your email address <b>{{userEmail}}</b>
        </p>
    </div>


    <div class="footer loginbuttons" *ngIf="!enableLogIn">
        <div *ngIf="!otpValidationView && !secondFactorValidationView">
            <div class="column">
                <div class="text-center" *ngIf="isMYEYLoginEnabled">
                    <p class="fw-bold mb-0">
                        Please click on the Sign In button to access the requested services
                    </p>
                    <br>
                    <button alt="Button" class="btn_primary py-2" (click)="login_Click()"><small>I am NOT an EY
                            Employee</small></button>&nbsp;
                    <button alt="Button" class="btn_default py-2" (click)="login_internal_Click()"><small>I am an EY
                            Employee</small></button>&nbsp;
                </div>
                <div class="divider" *ngIf="isMYEYLoginEnabled && enableOTPFeature">
                    <hr>
                    <div class="divider-text">or</div>
                    <hr>
                </div>
                <div class="text-center" *ngIf="enableOTPFeature">
                    <div class="d-flex justify-content-end mb-1">
                        <input type="text" class="fill-width flex-grow-1" placeholder="Email Address" id="emailId">
                    </div>
                    <div class="d-flex justify-content-end">
                        <button alt="Button" class="btn_primary py-2 flex-grow-1"
                            (click)="login_otp_Click()"><small>Send Verification
                                Code</small></button>
                    </div>
                </div>
            </div>
        </div>
        <ng-container [ngTemplateOutlet]="otpViewtemplate"></ng-container>
        <div>
            <a href="javascript:void(0)" style="text-align: justify;" class="cookie-modal"
                (click)="showCookiePopup_Click()">Having trouble signing in? Click here</a>
        </div>
    </div>
    <div class="footer loginbuttons" *ngIf="enableLogIn">
        <div class="d-flex justify-content-middle" *ngIf="!otpValidationView && !secondFactorValidationView">
            <div class="column">
                <div class="text-center" *ngIf="isMYEYLoginEnabled">
                    <p class="fw-bold mb-0">
                        Please click on the Sign In button to access the requested services
                    </p>
                    <br>
                    <div class="d-flex justify-content-end">
                        <button alt="Button" class="btn_primary py-2 flex-grow-1"
                            (click)="login_internal_Click()"><small>Sign
                                In</small></button>
                    </div>
                </div>
                <div class="divider" *ngIf="isMYEYLoginEnabled && enableOTPFeature">
                    <hr>
                    <div class="divider-text">or</div>
                    <hr>
                </div>
                <div class="text-center" *ngIf="enableOTPFeature">
                    <div class="d-flex justify-content-end mb-1">
                        <input type="text" class="fill-width flex-grow-1" placeholder="Email Address" id="emailId">
                    </div>
                    <div class="d-flex justify-content-end">
                        <button alt="Button" class="btn_primary py-2 flex-grow-1"
                            (click)="login_otp_Click()"><small>Send Verification
                                Code</small></button>
                    </div>
                </div>
            </div>
        </div>
        <ng-container [ngTemplateOutlet]="otpViewtemplate"></ng-container>
        <div class="text-center">
            <a href="javascript:void(0)" style="text-align: justify;" class="cookie-modal"
                (click)="showCookiePopup_Click()">Having trouble signing in? Click here</a>
        </div>
    </div>

</app-login-modal>
<ng-template #otpViewtemplate>
    <div class="d-flex justify-content-end mb-2" *ngIf="otpValidationView">
        <input type="text" class="fill-width flex-grow-1" [attr.maxlength]="maxlengthOTP"
            placeholder="Enter Verification Code" id="passcode">&nbsp;
    </div>
    <div class="d-flex justify-content-end mb-2" *ngIf="otpValidationView">
        <span>Didn't receive the verification code? <a href="javascript:void(0)"
                [ngClass]="{'disabled-link': resendDisabled,'link-color': !resendDisabled}"
                (click)="resendOTP()">Resend</a><span *ngIf="resendDisabled && countdown > 0">
                in {{ countdown }} second{{ countdown === 1 ? '' : 's' }}
            </span></span>
    </div>
    <div *ngIf="otpValidationView">
        <div class="d-flex justify-content-end mb-2">
            <button alt="Button" class="btn_primary py-2 flex-grow-1"
                (click)="validate_otp_Click()"><small>Verify</small></button>
        </div>
        <p class="error-message" *ngIf="otpError">{{otpErrorMessage}}</p>
    </div>
    <div *ngIf="secondFactorValidationView">
        <div class="d-flex mb-2" *ngIf="!isSecondAuthBypassed">
            <div *ngIf="!isSecondAuthBypassed && isPartnerCodeEnabled">
                <label class="placeholder mt-2" *ngIf="!isSecondAuthBypassed && isPartnerCodeEnabled">
                    Partner Code
                    <input type="radio" [checked]="isPartnerCodeOpted===true" [value]=false
                        (change)="partnerCodeOptionSelected()" />&nbsp;
                    <span class="checkmark radio"></span>
                </label>
            </div>
            <div *ngIf="!isSecondAuthBypassed && isTINenabled">
                <label class="placeholder mr-2 mt-2">
                    Tax Identification Number(Last 4 digits)
                    <input type="radio" [checked]="isSSNopted===true" [value]=true
                        (change)="ssnOptionSelected()" />&nbsp;
                    <span class="checkmark radio"></span>
                </label>
            </div>
            <div *ngIf="!isSecondAuthBypassed && isZipCodeenabled">
                <label class="placeholder mt-2 mr-2" *ngIf="!isSecondAuthBypassed && isZipCodeenabled">
                    Zip Code
                    <input type="radio" [checked]="isZipCodeOpted===true" [value]=false
                        (change)="postalCodeOptionSelected()" />&nbsp;
                    <span class="checkmark radio"></span>
                </label>
            </div>            
        </div>
        <div class="d-flex justify-content-end mb-2"
            *ngIf="!isSecondAuthBypassed && (isTINenabled || isZipCodeenabled || isPartnerCodeEnabled)">
            <input type="{{textBoxType}}" class="fill-width flex-grow-1" [attr.maxlength]="maxlengthSSNZip"
                placeholder="{{textForSSN}}" id="ssnzip">&nbsp;
        </div>
        <div class="d-flex justify-content-end mb-2">
            <button alt="Button" class="btn_primary py-2 flex-grow-1"
                (click)="validate2ndfactor_Click()"><small>Verify</small></button>
        </div>
        <p class="error-message" *ngIf="otpError">{{otpErrorMessage}}</p>
    </div>
</ng-template>

<ng-template #clientAdminLogin>
    <ng-container *ngTemplateOutlet="accordionHeader; context: { label: 'Client Admin Login '}">
    </ng-container>
</ng-template>
<ng-template #chromeAccordianHeader>
    <ng-container *ngTemplateOutlet="accordionHeader; context: { label: 'Google Chrome browser'}">
    </ng-container>
</ng-template>
<ng-template #edgeAccordianHeader>
    <ng-container *ngTemplateOutlet="accordionHeader; context: { label: 'Microsoft Edge browser'}">
    </ng-container>
</ng-template>
<ng-template #safariAccordianHeader>
    <ng-container *ngTemplateOutlet="accordionHeader; context: { label: 'Macbook - Safari Browser'}">
    </ng-container>
</ng-template>
<ng-template #safariIPhoneAccordianHeader>
    <ng-container *ngTemplateOutlet="accordionHeader; context: { label: 'iPhone/iPad - Safari Browser'}">
    </ng-container>
</ng-template>
<ng-template #iOSAccordianHeader>
    <ng-container *ngTemplateOutlet="accordionHeader; context: { label: 'iOS'}">
    </ng-container>
</ng-template>
<ng-template #otpInstructions>
    <ng-container *ngTemplateOutlet="accordionHeader; context: { label: 'Login Via Verification Code'}">
    </ng-container>
</ng-template>

<ng-template #accordionHeader let-label="label">
    <div class="align-self-center">
        <h4 class="mb-0 mr-auto">{{label}}</h4>
    </div>
</ng-template>

<app-modal [openAuto]="showBrowserSupportModal" crossHide=false draggable="true">
    <h4 class="app-modal-header mb-0">Browser Not Supported</h4>
    <div class="app-modal-body">
        This browser / browser version is not supported by State Composite Portal. Please use any of the below browsers
        for better support:
        <ul class="mb-0">
            <li *ngFor="let brsr of displayObject">
                <b>{{brsr.name}}</b>, Latest version: <b>{{brsr.version}}</b> and above
            </li>
        </ul>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn_secondary ml-3" (click)="closeBrowserSupportModal()">I Understand</button>-->
    <!-- </div>
</app-modal> -->

<!-- <app-modal [openAuto]="showClearCookieModal" crossHide=false draggable="true">
    <h4 class="app-modal-header mb-0">Instructions</h4>
    <div class="app-modal-body">
        <h6>Please follow the below instructions on how to clear cookies</h6>
        <app-accordion *ngIf = "!isMYEYLoginEnabled" class="d-block mt-4" [headerTemplate]="clientAdminLogin">
            <div class="content">
                <button alt="Button" class="btn_primary py-2 flex-grow-1"
                (click)="login_internal_Click()"><small>Sign
                    In</small></button>
            </div>
        </app-accordion>
        <app-accordion class="d-block mt-4" [headerTemplate]="chromeAccordianHeader">
            <div class="content">
                <ul>
                    <li>
                        At the top right, click "Customise and control Google Chrome".<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/chrome/Picture1.png" alt="
                        An image showing the Settings and more menu in Chrome.">
                    </li><br>
                    <li>
                        Click "More tools" and then "Clear browsing data".<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/chrome/Picture2.png" alt="
                        An image showing the Settings and more menu in Chrome.">
                    </li><br>
                    <li>
                        Select All time as time range and check both boxes for Cookies and Caches and hit Clear Data as
                        shown in the screenshot below.<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/chrome/Picture3-new.png" alt="
                        An image showing the Settings and more menu in Chrome.">
                    </li><br>
                    <li>Re-open the portal link and try logging in again.</li>
                </ul>
            </div>
        </app-accordion>
        <app-accordion class="d-block mt-4" [headerTemplate]="edgeAccordianHeader">
            <div class="content">
                <ul>
                    <li>
                        In Edge, select ‘Settings and more’ in the upper right corner of your browser window and go to
                        ‘Settings’.<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/edge/edge1.png" alt="
                        An image showing the Settings and more menu in Microsoft Edge.">
                    </li><br>
                    <li>
                        Go to ‘Privacy, search and services’ and click on ‘Choose what to clear’.<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/edge/edge2.PNG" alt="
                        An image showing the Settings and more menu in Microsoft Edge.">
                    </li><br>
                    <li>
                        Under Time range drop down menu, select ‘All time’, check the boxes as shown in the below
                        screenshot and click on ‘Clear now’.<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/edge/Picture6.png" alt="
                        An image showing the Settings and more menu in Microsoft Edge.">
                    </li><br>
                    <li>
                        Re-open the portal link and try logging in again.
                    </li><br>
                </ul>

                All your cookies and other site data will now be deleted for the time range you selected. This signs you
                out of most sites.
            </div>
        </app-accordion>
        <app-accordion class="d-block mt-4" [headerTemplate]="safariAccordianHeader">
            <div class="content">
                <ul>
                    <li>
                        Go to your browser ‘Settings’ under ‘Safari’.<br>
                        <img src="../../assets/images/clearcookie-images/macbook/Picture1.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-15rem img-fluid responsive-img">
                    </li><br>
                    <li>
                        Click ‘Advanced’ and uncheck ‘Block all cookies’<br>
                        <img src="../../assets/images/clearcookie-images/macbook/Picture2.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-15rem img-fluid responsive-img">
                    </li><br>
                    <li>
                        Click ‘Privacy’ and click ‘Manage Website Data’ button. It will open a list of all your browser’s cookies. Click ‘Remove All’.<br>
                        <img src="../../assets/images/clearcookie-images/macbook/Picture3.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-15rem img-fluid responsive-img"><br>
                            <img src="../../assets/images/clearcookie-images/macbook/Picture4.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-15rem img-fluid responsive-img">
                    </li><br>
                    <li>
                        Click ‘Remove Now’ when prompted and click ‘Done’.<br>
                        <img src="../../assets/images/clearcookie-images/macbook/Picture5.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-15rem img-fluid responsive-img"><br>
                            <img src="../../assets/images/clearcookie-images/macbook/Picture6.png" alt="
                            An image showing the Settings and more menu in Safari."
                                class="cookies-popup-img-15rem img-fluid responsive-img">    
                    </li><br>
                    <li>Re-open the portal link and try logging in again.</li><br>
                </ul>
            </div>
        </app-accordion>
        <app-accordion class="d-block mt-4" [headerTemplate]="safariIPhoneAccordianHeader">
            <div class="content">
                <ul>
                    <li>
                        Update the setting to unblock the third-party cookies: Please go to Setting and click on
                        Safari.<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/safari/Picture12.jpg" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-20rem">
                    </li><br>
                    <li>
                        Go to ‘Advanced’ settings.<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/safari/Picture17.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-20rem">
                    </li><br>
                    <li>
                        Please disable “Block All Cookies”<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/safari/Picture18.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-20rem">
                    </li><br>
                    <li>
                        Go to ‘Clear History and Website Data’ , select ‘All history’ and click ‘Clear History’.<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/safari/Picture19.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-20rem mr-2 my-1 border">
                            <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/safari/Picture20.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-20rem my-1 border">
                    </li><br>
                    <li>
                        Re-open the portal link and try logging in again.
                    </li><br>
                </ul><br>
            </div>
        </app-accordion>
        <h6 class="app-modal-header mb-0 mt-5" *ngIf="enableOTPFeature">Please follow below instructions for Login via Verification Code</h6>
        <app-accordion class="d-block mt-4" *ngIf="enableOTPFeature" [headerTemplate]="otpInstructions">
            <div class="content">
                <ul>
                    <li>
                        Login Page:<br>
                        <ul>
                            <li>
                                For login using verification code, please enter your valid email address and click on 'Send Verification Code' button to receive the code on the email address.<br>
                                <img src="../../assets/images/otp-images/LoginPage.png" alt="
                                An image showing the Login Page."
                                    class="cookies-popup-img-15rem img-fluid responsive-img">
                            </li><br>
                        </ul>
                    </li><br>
                    <li>
                        Verification Code:<br>
                        <ul>
                            <li>
                                Enter the verification code sent to your email address and Click On 'Verify' button<br>
                                <img src="../../assets/images/otp-images/VerificationCode.png" alt="
                                An image showing where the verification code should be entered."
                                    class="cookies-popup-img-15rem img-fluid responsive-img">
                            </li><br>
                        </ul>
                    </li><br>
                    <li>
                        Partner Code OR Tax Identification Number OR Zip Code:<br>
                        <ul>
                            <li>
                                Enter the Partner Code OR the last 4 digits of Tax Identification Number OR the Zip Code (whichever is applicable to you) for any of the partners that you have invested in.
                                If all the options are available to you, please select one of the radio buttons either for Partner Code or Tax Identification Number or Zip Code and provide the corresponding value.
                                Click on 'Verify' button after entering the required value.<br>
                                <img src="../../assets/images/otp-images/SecondAuth.png" alt="
                                An image showing the Email Address Page."
                                    class="cookies-popup-img-15rem img-fluid responsive-img">
                            </li><br>
                        </ul>
                    </li><br>
                </ul>
            </div>
        </app-accordion>
        <br>
        <h6>Still facing issues? Please contact the administrator!</h6>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn_secondary" (click)="closePopup_Click()">Close</button>
    </div>
</app-modal> --> 

<!-- NEW UI -->


<!-- <app-login-modal> -->
    <!-- <div class="content" *ngIf="otpValidationView || secondFactorValidationView">
        <a href="javascript:void(0)" class="back-btn" (click)="backBtnClicked()">
            Back</a>
        <br />
    </div>
    <div class="content" *ngIf="!isMYEYLoginEnabled && enableOTPFeature && !otpValidationView && !secondFactorValidationView">
        <p class="mb-0 text-center">
            Sign In using Verification Code
        </p>
    </div>
    <div class="content" *ngIf="secondFactorValidationView && isTINenabled && !isZipCodeenabled && !isPartnerCodeEnabled">
        <p class="mb-0 text-center">
            Enter the last 4 digits of Tax Identification Number to Sign In
        </p>
    </div>
    <div class="content" *ngIf="secondFactorValidationView && isZipCodeenabled && !isTINenabled && !isPartnerCodeEnabled">
        <p class="mb-0 text-center">
            Enter the Zip Code to Sign In
        </p>
    </div>
    <div class="content" *ngIf="secondFactorValidationView && !isZipCodeenabled && !isTINenabled && isPartnerCodeEnabled">
        <p class="mb-0 text-center">
            Enter the Partner Code to Sign In
        </p>
    </div>
    <div class="content" *ngIf="secondFactorValidationView && isZipCodeenabled && isTINenabled && !isPartnerCodeEnabled">
        <p class="mb-0 text-center">
            Enter the last 4 digits of Tax Identification Number or Zip Code to Sign In
        </p>
    </div>
    <div class="content" *ngIf="secondFactorValidationView && !isZipCodeenabled && isTINenabled && isPartnerCodeEnabled">
        <p class="mb-0 text-center">
            Enter the Partner Code or last 4 digits of Tax Identification Number to Sign In
        </p>
    </div>
    <div class="content" *ngIf="secondFactorValidationView && isZipCodeenabled && !isTINenabled && isPartnerCodeEnabled">
        <p class="mb-0 text-center">
            Enter the Partner Code or Zip Code to Sign In
        </p>
    </div>
    <div class="content" *ngIf="secondFactorValidationView && isTINenabled && isZipCodeenabled && isPartnerCodeEnabled">
        <p class="mb-0 text-center">
            Enter the Partner Code or last 4 digits of Tax Identification Number or Zip Code to Sign In
        </p>
    </div>
    <div class="content" *ngIf="otpValidationView && !secondFactorValidationView">
        <p class="mb-0 text-center">
            Enter the verification code sent to your email address <b>{{userEmail}}</b>
        </p>
    </div> -->


    <!-- <div class="footer loginbuttons" *ngIf="!enableLogIn">
        <div *ngIf="!otpValidationView && !secondFactorValidationView">
            <div class="column">
                <div class="text-center" *ngIf="isMYEYLoginEnabled">
                    <p class="fw-bold mb-0">
                        Please click on the Sign In button to access the requested services
                    </p>
                    <br>
                    <button alt="Button" class="btn_primary py-2" (click)="login_Click()"><small>I am NOT an EY
                            Employee</small></button>&nbsp;
                    <button alt="Button" class="btn_default py-2" (click)="login_internal_Click()"><small>I am an EY
                            Employee</small></button>&nbsp;
                </div>
                <div class="divider" *ngIf="isMYEYLoginEnabled && enableOTPFeature">
                    <hr>
                    <div class="divider-text">or</div>
                    <hr>
                </div>
                <div class="text-center" *ngIf="enableOTPFeature">
                    <div class="d-flex justify-content-end mb-1">
                        <input type="text" class="fill-width flex-grow-1" placeholder="Email Address" id="emailId">
                    </div>
                    <div class="d-flex justify-content-end">
                        <button alt="Button" class="btn_primary py-2 flex-grow-1"
                            (click)="login_otp_Click()"><small>Send Verification
                                Code</small></button>
                    </div>
                </div>
            </div>
        </div>
        <ng-container [ngTemplateOutlet]="otpViewtemplate"></ng-container>
        <div>
            <a href="javascript:void(0)" style="text-align: justify;" class="cookie-modal"
                (click)="showCookiePopup_Click()">Having trouble signing in? Click here</a>
        </div>
    </div> -->
    <!-- <div class="footer loginbuttons" *ngIf="enableLogIn">
        <div class="d-flex justify-content-middle" *ngIf="!otpValidationView && !secondFactorValidationView">
            <div class="column">
                <div class="text-center" *ngIf="isMYEYLoginEnabled">
                    <p class="fw-bold mb-0">
                        Please click on the Sign In button to access the requested services
                    </p>
                    <br>
                    <div class="d-flex justify-content-end">
                        <button alt="Button" class="btn_primary py-2 flex-grow-1"
                            (click)="login_internal_Click()"><small>Sign
                                In</small></button>
                    </div>
                </div>
                <div class="divider" *ngIf="isMYEYLoginEnabled && enableOTPFeature">
                    <hr>
                    <div class="divider-text">or</div>
                    <hr>
                </div>
                <div class="text-center" *ngIf="enableOTPFeature">
                    <div class="d-flex justify-content-end mb-1">
                        <input type="text" class="fill-width flex-grow-1" placeholder="Email Address" id="emailId">
                    </div>
                    <div class="d-flex justify-content-end">
                        <button alt="Button" class="btn_primary py-2 flex-grow-1"
                            (click)="login_otp_Click()"><small>Send Verification
                                Code</small></button>
                    </div>
                </div>
            </div>
        </div>
        <ng-container [ngTemplateOutlet]="otpViewtemplate"></ng-container>
        <div class="text-center">
            <a href="javascript:void(0)" style="text-align: justify;" class="cookie-modal"
                (click)="showCookiePopup_Click()">Having trouble signing in? Click here</a>
        </div>
    </div> -->

    <div class="loginView" *ngIf="enableLogIn">
        <img class="img-fluid responsive-img loginImage"
         src="../../assets/images/background.png" alt="Login Image">
        <div class="footer loginbuttons">
            <div [ngClass]="{'column mt-5': isMYEYLoginEnabled && enableOTPFeature, 'column mt-7': !enableOTPFeature || !isMYEYLoginEnabled}" class="column mt-5">
                <div class="loginLogo d-flex align-items-end mb-5">
                    <img class=" logo-img mr-2 ml-12 align-self-center" [src]="'assets/images/'+logo" alt="Logo" />
                    <h4 *ngIf="isEYBranded" class="ml-2 align-self-end mt-5">{{title}}</h4>
                </div>
                <div class="content" *ngIf="otpValidationView || secondFactorValidationView">
                    <a href="javascript:void(0)" class="back-btn" (click)="backBtnClicked()">
                        Back</a>
                    <br />
                </div>
                <div class="content" *ngIf="secondFactorValidationView && isTINenabled && !isZipCodeenabled && !isPartnerCodeEnabled">
                    <p class="mb-0 text-center">
                        Enter the last 4 digits of Tax Identification Number to Sign In
                    </p>
                </div>
                <div class="content" *ngIf="secondFactorValidationView && isZipCodeenabled && !isTINenabled && !isPartnerCodeEnabled">
                    <p class="mb-0 text-center">
                        Enter the Zip Code to Sign In
                    </p>
                </div>
                <div class="content" *ngIf="secondFactorValidationView && !isZipCodeenabled && !isTINenabled && isPartnerCodeEnabled">
                    <p class="mb-0 text-center">
                        Enter the Partner Code to Sign In
                    </p>
                </div>
                <div class="content" *ngIf="secondFactorValidationView && isZipCodeenabled && isTINenabled && !isPartnerCodeEnabled">
                    <p class="mb-0 text-center">
                        Enter the last 4 digits of Tax Identification Number or Zip Code to Sign In
                    </p>
                </div>
                <div class="content" *ngIf="secondFactorValidationView && !isZipCodeenabled && isTINenabled && isPartnerCodeEnabled">
                    <p class="mb-0 text-center">
                        Enter the Partner Code or last 4 digits of Tax Identification Number to Sign In
                    </p>
                </div>
                <div class="content" *ngIf="secondFactorValidationView && isZipCodeenabled && !isTINenabled && isPartnerCodeEnabled">
                    <p class="mb-0 text-center">
                        Enter the Partner Code or Zip Code to Sign In
                    </p>
                </div>
                <div class="content" *ngIf="secondFactorValidationView && isTINenabled && isZipCodeenabled && isPartnerCodeEnabled">
                    <p class="mb-0 text-center">
                        Enter the Partner Code or last 4 digits of Tax Identification Number or Zip Code to Sign In
                    </p>
                </div>
                <div class="content" *ngIf="otpValidationView && !secondFactorValidationView">
                    <p class="mb-0 text-center">
                        Enter the verification code sent to your email address <b>{{userEmail}}</b>
                    </p>
                </div>
                <!-- <div class="d-flex justify-content-center align-self-center"><h2>{{title}}</h2></div> -->
                <div class="text-center" *ngIf="isMYEYLoginEnabled && !otpValidationView && !secondFactorValidationView">
                    <h5 class="fw-bold mb-0 loginFont">
                        Please click on the Sign In button to access the requested services
                    </h5>
                    <br>
                    <div class="d-flex justify-content-center mt-4">
                        <button alt="Button" class="btn_primary"
                            (click)="login_internal_Click()"><small class="signInText">SIGN
                                IN</small></button>
                    </div>
                </div>
                <div class="divider" *ngIf="isMYEYLoginEnabled && enableOTPFeature && !otpValidationView && !secondFactorValidationView">
                    <hr>
                    <div class="divider-text">OR</div>
                    <hr>
                </div>
                <div class="otp-container">
                <h5 *ngIf="enableOTPFeature && !otpValidationView && !secondFactorValidationView" class="fw-bold mb-0 loginFont otpView text-center">
                    Enter your email address in the form below to send the verification code.
                </h5>
                <br>
                <div class="otpView" *ngIf="enableOTPFeature && !otpValidationView && !secondFactorValidationView">
                    <div class="d-flex justify-content-end mb-2">
                        <!-- <input type="text" class="fill-width flex-grow-1" placeholder="Email Address" id="emailId"> -->
                        <app-textbox class="fill-width flex-grow-1" [(ngModel)] = "emailId" type="email" name="emailId" id="emailId"  label="Email Address"></app-textbox>
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                        <button alt="Button" class="otpTextbox btn_secondary"
                            (click)="login_otp_Click()"><small class="signInText">Send Verification
                                Code</small></button>
                    </div>
                </div>
                <ng-container [ngTemplateOutlet]="otpViewtemplate"></ng-container>
                 <div class="d-flex justify-content-center">
                <a href="javascript:void(0)" style="text-align: justify;" class="cookie-modal"
                    (click)="showCookiePopup_Click()">Having trouble signing in? Click here</a>
                </div>
            </div>
            </div>
        </div>
       
    </div>
    <div>
    <footer class="page-footer d-flex">
        <div
            class="footer-content text-white text-center align-self-center flex-fill d-flex align-items-center justify-content-center">
            <!-- <img class="footer-logo" *ngIf="showFooterLogo" [src]="'assets/images/'+footerLogo"
                alt="brand-logo" /> -->
            <p class="footerText mb-0 ">
                <fa-icon [icon]="faCopyright"></fa-icon>
                2024 Ernst & Young LLP | All Rights Reserved | Content within this application is confidential
                and meant for EY staff and EY clients only. Please review the <a class="footer-link" (click)="openLink(scpPrivacyNotice)">Privacy Notice</a> and <a class="footer-link" (click)="openLink(scpTerms)">Terms of Use</a> for additional information. To adjust cookie settings, see the <a class="footer-link" (click)="openLink(scpTerms)">Cookie policy</a>
                <br>
                <span style="font-size: 10px;">Please do not enter any unnecessary personal information or any sensitive personal data (including government identifiers such as tax file numbers or social security numbers), client confidential information, audit secrets, state secrets, commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive/irrelevant</span>
            </p>
        </div>
    </footer>
    </div>
    

<!-- </app-login-modal> -->
<ng-template #otpViewtemplate>
    <div class="d-flex justify-content-end mb-2" *ngIf="otpValidationView">
        <!-- <input type="text" class="fill-width flex-grow-1" [attr.maxlength]="maxlengthOTP"
            placeholder="Enter Verification Code" id="passcode">&nbsp; -->
        <app-textbox class="fill-width flex-grow-1"  [(ngModel)] = "verificationCode" type="verificationCode" name="verificationCode" id="verificationCode"  label="Enter Verification Code"></app-textbox>&nbsp;
    </div>
    <div class="d-flex justify-content-end mb-2" *ngIf="otpValidationView">
        <span>Didn't receive the verification code? <a href="javascript:void(0)"
                [ngClass]="{'disabled-link': resendDisabled,'link-color': !resendDisabled}"
                (click)="resendOTP()">Resend</a><span *ngIf="resendDisabled && countdown > 0">
                in {{ countdown }} second{{ countdown === 1 ? '' : 's' }}
            </span></span>
    </div>
    <div *ngIf="otpValidationView">
        <div class="d-flex justify-content-end mb-2">
            <button alt="Button" class="btn_primary py-2 flex-grow-1"
                (click)="validate_otp_Click()"><small>Verify</small></button>
        </div>
        <p class="error-message" *ngIf="otpError">{{otpErrorMessage}}</p>
    </div>
    <div *ngIf="secondFactorValidationView">
        <div class="d-flex mb-2" *ngIf="!isSecondAuthBypassed">
            <div *ngIf="!isSecondAuthBypassed && isPartnerCodeEnabled">
                <label class="placeholder mt-2" *ngIf="!isSecondAuthBypassed && isPartnerCodeEnabled">
                    Partner Code
                    <input type="radio" [checked]="isPartnerCodeOpted===true" [value]=false
                        (change)="partnerCodeOptionSelected()" />&nbsp;
                    <span class="checkmark radio"></span>
                </label>
            </div>
            <div *ngIf="!isSecondAuthBypassed && isTINenabled">
                <label class="placeholder mr-2 mt-2">
                    Tax Identification Number(Last 4 digits)
                    <input type="radio" [checked]="isSSNopted===true" [value]=true
                        (change)="ssnOptionSelected()" />&nbsp;
                    <span class="checkmark radio"></span>
                </label>
            </div>
            <div *ngIf="!isSecondAuthBypassed && isZipCodeenabled">
                <label class="placeholder mt-2 mr-2" *ngIf="!isSecondAuthBypassed && isZipCodeenabled">
                    Zip Code
                    <input type="radio" [checked]="isZipCodeOpted===true" [value]=false
                        (change)="postalCodeOptionSelected()" />&nbsp;
                    <span class="checkmark radio"></span>
                </label>
            </div>            
        </div>
        <div class="d-flex justify-content-end mb-2"
            *ngIf="!isSecondAuthBypassed && (isTINenabled || isZipCodeenabled || isPartnerCodeEnabled)">
            <!-- <input type="{{textBoxType}}" class="fill-width flex-grow-1" [attr.maxlength]="maxlengthSSNZip"
                placeholder="{{textForSSN}}" id="ssnzip">&nbsp; -->
            <app-textbox class="fill-width flex-grow-1"  [(ngModel)] = "mfaValue" type="{{textBoxType}}" name="mfaValue" id="mfaValue"  label="{{textForSSN}}"></app-textbox>&nbsp;

        </div>
        <div class="d-flex justify-content-end mb-2">
            <button alt="Button" class="btn_primary py-2 flex-grow-1"
                (click)="validate2ndfactor_Click()"><small>Verify</small></button>
        </div>
        <p class="error-message" *ngIf="otpError">{{otpErrorMessage}}</p>
    </div>
</ng-template>

<ng-template #clientAdminLogin>
    <ng-container *ngTemplateOutlet="accordionHeader; context: { label: 'Client Admin Login '}">
    </ng-container>
</ng-template>
<ng-template #chromeAccordianHeader>
    <ng-container *ngTemplateOutlet="accordionHeader; context: { label: 'Google Chrome browser'}">
    </ng-container>
</ng-template>
<ng-template #edgeAccordianHeader>
    <ng-container *ngTemplateOutlet="accordionHeader; context: { label: 'Microsoft Edge browser'}">
    </ng-container>
</ng-template>
<ng-template #safariAccordianHeader>
    <ng-container *ngTemplateOutlet="accordionHeader; context: { label: 'Macbook - Safari Browser'}">
    </ng-container>
</ng-template>
<ng-template #safariIPhoneAccordianHeader>
    <ng-container *ngTemplateOutlet="accordionHeader; context: { label: 'iPhone/iPad - Safari Browser'}">
    </ng-container>
</ng-template>
<ng-template #iOSAccordianHeader>
    <ng-container *ngTemplateOutlet="accordionHeader; context: { label: 'iOS'}">
    </ng-container>
</ng-template>
<ng-template #otpInstructions>
    <ng-container *ngTemplateOutlet="accordionHeader; context: { label: 'Login Via Verification Code'}">
    </ng-container>
</ng-template>

<ng-template #accordionHeader let-label="label">
    <div class="align-self-center">
        <h4 class="mb-0 mr-auto">{{label}}</h4>
    </div>
</ng-template>

<app-modal [openAuto]="showBrowserSupportModal" crossHide=false draggable="true">
    <h4 class="app-modal-header mb-0">Browser Not Supported</h4>
    <div class="app-modal-body">
        The current browser is outdated and may not have full portal functionality. Please use any of the below browser versions for full functionality.
        <ul class="mb-0">
            <li *ngFor="let brsr of displayObject">
                <span *ngIf="brsr.version!=null"><b>{{brsr.name}}</b>, Latest version: <b>{{brsr.version}}</b> and above</span>
                <span *ngIf="brsr.version==null"><b>{{brsr.name}}</b>: Unsupported</span>
            </li>
        </ul>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn_secondary ml-3" (click)="closeBrowserSupportModal()">I Understand</button>
    </div>
</app-modal>

<app-modal [openAuto]="showClearCookieModal" crossHide=false draggable="true">
    <h4 class="app-modal-header mb-0">Instructions</h4>
    <div class="app-modal-body">
        <h6>Please follow the below instructions on how to clear cookies</h6>
        <app-accordion *ngIf = "!isMYEYLoginEnabled" class="d-block mt-4" [headerTemplate]="clientAdminLogin">
            <div class="content">
                <button alt="Button" class="btn_primary py-2 flex-grow-1"
                (click)="login_internal_Click()"><small>Sign
                    In</small></button>
            </div>
        </app-accordion>
        <app-accordion class="d-block mt-4" [headerTemplate]="chromeAccordianHeader">
            <div class="content">
                <ul>
                    <li>
                        At the top right, click "Customise and control Google Chrome".<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/chrome/Picture1.png" alt="
                        An image showing the Settings and more menu in Chrome.">
                    </li><br>
                    <li>
                        Click "More tools" and then "Clear browsing data".<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/chrome/Picture2.png" alt="
                        An image showing the Settings and more menu in Chrome.">
                    </li><br>
                    <li>
                        Select All time as time range and check both boxes for Cookies and Caches and hit Clear Data as
                        shown in the screenshot below.<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/chrome/Picture3-new.png" alt="
                        An image showing the Settings and more menu in Chrome.">
                    </li><br>
                    <li>Re-open the portal link and try logging in again.</li>
                </ul>
            </div>
        </app-accordion>
        <app-accordion class="d-block mt-4" [headerTemplate]="edgeAccordianHeader">
            <div class="content">
                <ul>
                    <li>
                        In Edge, select ‘Settings and more’ in the upper right corner of your browser window and go to
                        ‘Settings’.<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/edge/edge1.png" alt="
                        An image showing the Settings and more menu in Microsoft Edge.">
                    </li><br>
                    <li>
                        Go to ‘Privacy, search and services’ and click on ‘Choose what to clear’.<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/edge/edge2.PNG" alt="
                        An image showing the Settings and more menu in Microsoft Edge.">
                    </li><br>
                    <li>
                        Under Time range drop down menu, select ‘All time’, check the boxes as shown in the below
                        screenshot and click on ‘Clear now’.<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/edge/Picture6.png" alt="
                        An image showing the Settings and more menu in Microsoft Edge.">
                    </li><br>
                    <li>
                        Re-open the portal link and try logging in again.
                    </li><br>
                </ul>

                All your cookies and other site data will now be deleted for the time range you selected. This signs you
                out of most sites.
            </div>
        </app-accordion>
        <app-accordion class="d-block mt-4" [headerTemplate]="safariAccordianHeader">
            <div class="content">
                <ul>
                    <li>
                        Go to your browser ‘Settings’ under ‘Safari’.<br>
                        <img src="../../assets/images/clearcookie-images/macbook/Picture1.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-15rem img-fluid responsive-img">
                    </li><br>
                    <li>
                        Click ‘Advanced’ and uncheck ‘Block all cookies’<br>
                        <img src="../../assets/images/clearcookie-images/macbook/Picture2.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-15rem img-fluid responsive-img">
                    </li><br>
                    <li>
                        Click ‘Privacy’ and click ‘Manage Website Data’ button. It will open a list of all your browser’s cookies. Click ‘Remove All’.<br>
                        <img src="../../assets/images/clearcookie-images/macbook/Picture3.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-15rem img-fluid responsive-img"><br>
                            <img src="../../assets/images/clearcookie-images/macbook/Picture4.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-15rem img-fluid responsive-img">
                    </li><br>
                    <li>
                        Click ‘Remove Now’ when prompted and click ‘Done’.<br>
                        <img src="../../assets/images/clearcookie-images/macbook/Picture5.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-15rem img-fluid responsive-img"><br>
                            <img src="../../assets/images/clearcookie-images/macbook/Picture6.png" alt="
                            An image showing the Settings and more menu in Safari."
                                class="cookies-popup-img-15rem img-fluid responsive-img">    
                    </li><br>
                    <li>Re-open the portal link and try logging in again.</li><br>
                </ul>
            </div>
        </app-accordion>
        <app-accordion class="d-block mt-4" [headerTemplate]="safariIPhoneAccordianHeader">
            <div class="content">
                <ul>
                    <li>
                        Update the setting to unblock the third-party cookies: Please go to Setting and click on
                        Safari.<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/safari/Picture12.jpg" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-20rem">
                    </li><br>
                    <li>
                        Go to ‘Advanced’ settings.<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/safari/Picture17.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-20rem">
                    </li><br>
                    <li>
                        Please disable “Block All Cookies”<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/safari/Picture18.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-20rem">
                    </li><br>
                    <li>
                        Go to ‘Clear History and Website Data’ , select ‘All history’ and click ‘Clear History’.<br>
                        <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/safari/Picture19.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-20rem mr-2 my-1 border">
                            <img class="img-fluid responsive-img"
                            src="../../assets/images/clearcookie-images/safari/Picture20.png" alt="
                        An image showing the Settings and more menu in Safari."
                            class="cookies-popup-img-20rem my-1 border">
                    </li><br>
                    <li>
                        Re-open the portal link and try logging in again.
                    </li><br>
                </ul><br>
            </div>
        </app-accordion>
        <h6 class="app-modal-header mb-0 mt-5" *ngIf="enableOTPFeature">Please follow below instructions for Login via Verification Code</h6>
        <app-accordion class="d-block mt-4" *ngIf="enableOTPFeature" [headerTemplate]="otpInstructions">
            <div class="content">
                <ul>
                    <li>
                        Login Page:<br>
                        <ul>
                            <li>
                                For login using verification code, please enter your valid email address and click on 'Send Verification Code' button to receive the code on the email address.<br>
                                <img src="../../assets/images/otp-images/LoginPage.png" alt="
                                An image showing the Login Page."
                                    class="cookies-popup-img-15rem img-fluid responsive-img">
                            </li><br>
                        </ul>
                    </li><br>
                    <li>
                        Verification Code:<br>
                        <ul>
                            <li>
                                Enter the verification code sent to your email address and Click On 'Verify' button<br>
                                <img src="../../assets/images/otp-images/VerificationCode.png" alt="
                                An image showing where the verification code should be entered."
                                    class="cookies-popup-img-15rem img-fluid responsive-img">
                            </li><br>
                        </ul>
                    </li><br>
                    <li>
                        Partner Code OR Tax Identification Number OR Zip Code:<br>
                        <ul>
                            <li>
                                Enter the Partner Code OR the last 4 digits of Tax Identification Number OR the Zip Code (whichever is applicable to you) for any of the partners that you have invested in.
                                If all the options are available to you, please select one of the radio buttons either for Partner Code or Tax Identification Number or Zip Code and provide the corresponding value.
                                Click on 'Verify' button after entering the required value.<br>
                                <img src="../../assets/images/otp-images/SecondAuth.png" alt="
                                An image showing the Email Address Page."
                                    class="cookies-popup-img-15rem img-fluid responsive-img">
                            </li><br>
                        </ul>
                    </li><br>
                </ul>
            </div>
        </app-accordion>
        <br>
        <h6>Still facing issues? Please contact the administrator!</h6>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn_secondary" (click)="closePopup_Click()">Close</button>
    </div>
</app-modal>

<!-- NEW UI -->